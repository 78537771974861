import * as React from "react";
import styled from "styled-components";
import COLORS from "@utils/COLORS";
import DEVICE from "@utils/DEVICE";
import SectionContainer from "@components/SectionContainer";
import { Body1 } from "@components/Typo";
import { TelIcon } from "@components/shared/Icons";
import RegisterForm from "./RegisterForm";
import { BUSINESS_REGISTER_FORM_DOM_ID } from "../../config/constants";

const DownloadDocumentForm = () => {
  return (
    <SectionContainer size="large" color="white">
      <Wrapper id={BUSINESS_REGISTER_FORM_DOM_ID}>
        <RegisterForm />
      </Wrapper>
      <ContactWrapper>
        <Body1>
          お電話でも承ります。平日10:00〜17:00
          <br />
          お気軽にご連絡ください。
        </Body1>
        <Tel>
          <a href="tel:03-4356-8998">
            <TelIconWrapper>
              <TelIcon height={30} />
            </TelIconWrapper>
            <span>03-4356-8998</span>
          </a>
        </Tel>
      </ContactWrapper>
    </SectionContainer>
  );
};

export default DownloadDocumentForm;

const Wrapper = styled.div`
  background-color: ${COLORS.white};
  text-align: center;
  max-width: 100%;
  @media ${DEVICE.MOBILE} {
    display: block;
    text-align: center;
    overflow: hidden;
    position: relative;
  }
`;

const ContactWrapper = styled.div`
  max-width: 350px;
  margin: 0 auto;
  margin-top: 32px;
  @media ${DEVICE.MOBILE} {
    padding-left: 12px;
  }
`;

const Tel = styled.div`
  display: block;
  position: relative;
  span {
    font-size: 30px;
    color: ${COLORS.charblue};
  }
`;

const TelIconWrapper = styled.span`
  position: relative;
  top: 12px;
  margin-right: 12px;
`;
